import React from 'react'
import './Header.css'

const Header = () => {
    return (
        <div className="header">
            <div className="logo" />
        </div>
    )
}

export default Header